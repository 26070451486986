import React, {FC, useEffect, useState} from 'react';
import {Select, Drawer, Form, Input} from 'antd';
import Button from '../../../../components/Button';
import Text from '../../../../components/Text';
import ContentContainer from '../../../../components/ContentContainer';
import TaxData from './TaxData';
import customerApi from '../../../../Api/Customer';
import searchApi from '../../../../Api/Search';
import {ITaxOffice} from '../../../../Api/Search/types';
import {ICustomerCreateComponent} from '../types';
import {ICustomerQuery} from '../../../../Api/Customer/types';
import {useAppDispatch, useAppSelector} from '../../../../hooks/useRedux';
import {setCustomerQueryData} from '../../../../store/customer/customerActions';
import {fetchCities} from '../../../../store/globals/globalsActions';

const {Option} = Select;

const GeneralInfo: FC<ICustomerCreateComponent> = ({onSubmit, onCancel}) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const [taxOffices, setTaxOffices] = useState<ITaxOffice[]>([]);
  const [isLoadingtaxOffices, setIsLoadingTaxOffices] = useState(false);
  const [isLoadingQueryCustomer, setIsLoadingQueryCustomer] = useState(false);
  const [isInfoDrawerVisible, setIsInfoDrawerVisible] = useState(false);

  const [province, setProvince] = useState<number>();
  const [customerData, setCustomerData] = useState<ICustomerQuery | any>(); // TODO: any!
  const [isCustomerQueryDataSaved, setIsCustomerQueryDataSaved] = useState(false);
  const {cities, isLoadingCities} = useAppSelector((state) => state.globals);

  const toggleDrawer = () => setIsInfoDrawerVisible((isVisible) => !isVisible);

  const fetchTaxOffices = async (provinceCode: number) => {
    try {
      setIsLoadingTaxOffices(true);
      const taxOfficesData = await searchApi.fetchTaxOffices(provinceCode);
      setTaxOffices(taxOfficesData);
    } catch {
      setTaxOffices([]);
    } finally {
      setIsLoadingTaxOffices(false);
    }
  };
  const queryCustomer = async ({
    id,
    taxOffice,
    customerKindId,
  }: {
    id: number;
    taxOffice: number;
    customerKindId: number;
  }) => {
    try {
      setIsLoadingQueryCustomer(true);
      const taxOfficeData = taxOffices.find(({value}) => value === taxOffice);
      const customer = await customerApi.queryCustomer(id.toString(), taxOfficeData?.label);
      setCustomerData({...customer, customerKindId});
      setIsInfoDrawerVisible(true);
    } finally {
      setIsLoadingQueryCustomer(false);
    }
  };

  const onChangeProvince = (provinceCode: number) => {
    setProvince(provinceCode);
    fetchTaxOffices(provinceCode);
    form.setFieldsValue({taxOffice: undefined});
  };
  const saveTaxData = () => {
    setIsCustomerQueryDataSaved(true);
    setIsInfoDrawerVisible(false);
  };
  const handleOnSubmit = () => {
    dispatch(setCustomerQueryData(customerData));
    onSubmit();
  };

  useEffect(() => {
    dispatch(fetchCities());
  }, []);

  return (
    <div>
      <Text type="h2" bold>
        Temel Bilgiler{' '}
        {/* {isCustomerQueryDataSaved && (
          <Button onClick={() => dowloadDivAsImage('customer-tax-data')}>
            <Text type="h4" color="smoke" underlined>
              Bu Sayfayı Pdf Olarak İndir <Icon icon="file-download" />
            </Text>
          </Button>
        )} */}
      </Text>
      <ContentContainer maxWidth={isCustomerQueryDataSaved ? 600 : 400}>
        {isCustomerQueryDataSaved ? (
          <TaxData
            accountId={customerData?.accountId}
            title={customerData?.title}
            taxId={customerData?.taxId}
            taxAdministration={customerData?.taxAdministration}
            address={customerData?.address}
            startDate={customerData?.startDate}
            operationDetail={customerData?.operationDetail}
            profits={customerData?.profits || []}
            onSubmit={handleOnSubmit}
            onCancel={onCancel}
          />
        ) : (
          <>
            <Form form={form} layout="vertical" requiredMark={false} onFinish={queryCustomer}>
              <Form.Item
                hasFeedback
                name="id"
                label="VKN / TCKN"
                rules={[
                  {
                    validator: (_, value) =>
                      value?.toString().length === 11 || value?.toString().length === 10
                        ? Promise.resolve()
                        : Promise.reject(new Error('Geçerlı bir VKN / TCKN giriniz!')),
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>

              <Form.Item name="province" label="Şehir" rules={[{required: true}]}>
                <Select
                  placeholder="örn. İstanbul"
                  optionFilterProp="label"
                  options={cities}
                  disabled={isLoadingCities}
                  loading={isLoadingCities}
                  showSearch
                  onChange={onChangeProvince}
                />
              </Form.Item>

              <Form.Item name="taxOffice" label="Vergi Dairesi" rules={[{required: true}]}>
                <Select
                  options={taxOffices}
                  disabled={!province}
                  loading={isLoadingtaxOffices}
                  optionFilterProp="label"
                  autoClearSearchValue
                  showSearch
                />
              </Form.Item>

              <Form.Item name="customerKindId" label="Firma Türü" rules={[{required: true}]}>
                <Select autoClearSearchValue>
                  <Option value={1}>Müşteri</Option>
                  <Option value={2}>Tedarikçi</Option>
                  <Option value={3}>Rakip</Option>
                  <Option value={4}>Diğer</Option>
                </Select>
              </Form.Item>

              <Form.Item className="m-0">
                <Button
                  className="mt"
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={isLoadingQueryCustomer}
                  block
                >
                  Sorgula
                </Button>
              </Form.Item>
            </Form>

            <Drawer
              title="Vergi Levhası"
              width={600}
              visible={isInfoDrawerVisible}
              onClose={toggleDrawer}
            >
              <TaxData
                accountId={customerData?.accountId}
                title={customerData?.title}
                taxId={customerData?.taxId}
                taxAdministration={customerData?.taxAdministration}
                address={customerData?.address}
                startDate={customerData?.startDate}
                operationDetail={customerData?.operationDetail}
                profits={customerData?.profits || []}
                onSubmit={saveTaxData}
                onCancel={toggleDrawer}
              />
            </Drawer>
          </>
        )}
      </ContentContainer>
    </div>
  );
};

export default GeneralInfo;
