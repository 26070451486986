import {customerActionTypes} from './customerTypes';

const initialState = {
  customerCreateQueryData: {
    accountId: 0,
    address: '',
    operationDetail: '',
    profits: [],
    startDate: '',
    taxAdministration: '',
    taxId: '',
    title: '',
    customerKindId: 0,
  },
  insuranceRating: {},
  customerSale: {},
};

export default (
  state = {...initialState},
  action: {type: string; payload: any} = {type: '', payload: {}},
): typeof initialState => {
  switch (action.type) {
    case customerActionTypes.SET_CUSTOMER_CREATE_QUERY_DATA:
      return {...state, customerCreateQueryData: action.payload};
    case customerActionTypes.SET_CUSTOMER_SALE_DATA:
      return {...state, customerSale: action.payload};
    case customerActionTypes.SET_INSURANCE_RATING_DATA:
      return {...state, insuranceRating: action.payload};
    case customerActionTypes.RESET_CUSTOMER_CREATE_QUERY_DATA:
      return {...state, customerCreateQueryData: initialState.customerCreateQueryData};

    default:
      return state;
  }
};
