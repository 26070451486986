import React, {FC, useEffect, useState} from 'react';
import {Col, Form, Input, notification, Row, Spin} from 'antd';
import Button from '../../../../components/Button';
import Text from '../../../../components/Text';
import Upload from '../../../../components/Upload';
import ProgressStep from '../../../../components/ProgressStep';
import {convertFileToAntdFile, FilePromise, getBase64, normFile} from '../../../../utils/file';
import {ICustomerCreateComponent} from '../types';
import customerApi from '../../../../Api/Customer';
import {useAppSelector} from '../../../../hooks/useRedux';
import searchApi from '../../../../Api/Search';
import {IApiImage} from '../../../../Api/types';

const LegalDocuments: FC<ICustomerCreateComponent> = ({onSubmit, onCancel}) => {
  const [form] = Form.useForm();
  const [legalData, setLegalData] = useState<any>(null);
  const [isLoadingInitialData, setIsLoadingInitialData] = useState(true);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const customer = useAppSelector((state) => state.customer.customerCreateQueryData);

  const fetchInitialData = async () => {
    const {
      activityCertificateDocument,
      circularAuthorizedSignatureDocument,
      tradeRegistryGazetteDocument,
      taxCertificateDocument,
      otherDocument,
    }: any = await customerApi.fetchCustomerLegalDocumentInfo(customer.accountId);
    setLegalData({
      activityCertificateDocument,
      circularAuthorizedSignatureDocument,
      tradeRegistryGazetteDocument,
      taxCertificateDocument,
      otherDocument,
    });
    const documents = await Promise.all([
      activityCertificateDocument && FilePromise(activityCertificateDocument),
      circularAuthorizedSignatureDocument && FilePromise(circularAuthorizedSignatureDocument),
      tradeRegistryGazetteDocument && FilePromise(tradeRegistryGazetteDocument),
      taxCertificateDocument && FilePromise(taxCertificateDocument),
      otherDocument && FilePromise(otherDocument),
    ]);

    const [
      activityCertificateFile,
      circularAuthorizedSignatureFile,
      tradeRegistryGazetteFile,
      taxCertificateFile,
      otherFile,
    ] = documents.map((file: any) => file && convertFileToAntdFile(file));

    form.setFieldsValue({
      activityCertificateDocument: activityCertificateFile ? [activityCertificateFile] : [],
      circularAuthorizedSignatureDocument: circularAuthorizedSignatureFile
        ? [circularAuthorizedSignatureFile]
        : [],
      taxCertificateDocument: taxCertificateFile ? [taxCertificateFile] : [],
      tradeRegistryGazetteDocument: tradeRegistryGazetteFile ? [tradeRegistryGazetteFile] : [],
      otherDocument: otherFile ? [otherFile] : [],
    });
    setIsLoadingInitialData(false);
  };

  const handleOnSubmit = async (formValues: any) => {
    try {
      setIsLoadingSubmit(true);
      const originFiles = [
        formValues.circularAuthorizedSignatureDocument?.[0]?.originFileObj,
        formValues.tradeRegistryGazetteDocument?.[0]?.originFileObj,
        formValues.taxCertificateDocument?.[0]?.originFileObj,
        formValues.activityCertificateDocument?.[0]?.originFileObj,
        formValues.otherDocument?.[0]?.originFileObj,
      ];
      const base64Files = originFiles.map((file) => file && getBase64(file));
      const [
        circularAuthorizedSignatureDocument,
        tradeRegistryGazetteDocument,
        taxCertificateDocument,
        activityCertificateDocument,
        otherDocument,
      ] = (await Promise.all(base64Files)) as IApiImage[];
      console.log(formValues);

      await customerApi.uploadCustomerLegalDocumentInfo({
        accountId: customer.accountId,
        circularAuthorizedSignatureDocument,
        tradeRegistryGazetteDocument,
        taxCertificateDocument,
        activityCertificateDocument,
        otherDocument,
      });
      onSubmit();
    } catch {
      setIsLoadingSubmit(false);
    }
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  const handleDeleteDocument = (file: any, key: any) => {
    searchApi.deleteDocument(file.tciDoumentId).then((res) => {
      console.log(res, 'res');
      notification.success({message: 'İşlem Başarılı', description: 'Dosya silindi yanıtı'});
      form.setFieldsValue({[key]: []});
    });
  };

  return (
    <Spin spinning={isLoadingInitialData}>
      <ProgressStep className="mb-big" title="Legal Evraklar" value={7} steps={8} />
      <Text type="h2" bold>
        Legal Evraklar
      </Text>

      <Form
        form={form}
        name="legalDocuments"
        layout="vertical"
        requiredMark={false}
        initialValues={{company: customer.title}}
        onFinish={handleOnSubmit}
      >
        <Row>
          <Col span={10}>
            <Form.Item name="company" label="Firma Adı">
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col>
            <Text type="h4" weight="initial" bold>
              İmza Sürküleri
            </Text>
            <Form.Item
              name="circularAuthorizedSignatureDocument"
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload
                label="PDF Yükle"
                accept=".doc,.docx,application/pdf"
                documentId={legalData?.circularAuthorizedSignatureDocument?.tciDoumentId}
                onRemove={() =>
                  handleDeleteDocument(
                    legalData.circularAuthorizedSignatureDocument,
                    'circularAuthorizedSignatureDocument',
                  )
                }
              />
            </Form.Item>
          </Col>
          <Col>
            <Text type="h4" weight="initial" bold>
              Ticaret Sicil Gazetesi
            </Text>
            <Form.Item
              name="tradeRegistryGazetteDocument"
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload
                label="PDF Yükle"
                accept=".doc,.docx,application/pdf"
                documentId={legalData?.tradeRegistryGazetteDocument?.tciDoumentId}
                onRemove={() =>
                  handleDeleteDocument(
                    legalData.tradeRegistryGazetteDocument,
                    'tradeRegistryGazetteDocument',
                  )
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col>
            <Text type="h4" weight="initial" bold>
              Vergi lehvası
            </Text>
            <Form.Item
              name="taxCertificateDocument"
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload
                label="PDF Yükle"
                accept=".doc,.docx,application/pdf"
                documentId={legalData?.taxCertificateDocument?.tciDoumentId}
                onRemove={() =>
                  handleDeleteDocument(legalData.taxCertificateDocument, 'taxCertificateDocument')
                }
              />
            </Form.Item>
          </Col>
          <Col>
            <Text type="h4" weight="initial" bold>
              Faaliyet Belgesi
            </Text>
            <Form.Item
              name="activityCertificateDocument"
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload
                label="PDF Yükle"
                accept=".doc,.docx,application/pdf"
                documentId={legalData?.activityCertificateDocument?.tciDoumentId}
                onRemove={() =>
                  handleDeleteDocument(
                    legalData.activityCertificateDocument,
                    'activityCertificateDocument',
                  )
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Text type="h4" weight="initial" bold>
          Diğer
        </Text>
        <Form.Item name="otherDocument" valuePropName="fileList" getValueFromEvent={normFile}>
          <Upload
            label="PDF Yükle"
            accept=".doc,.docx,application/pdf"
            documentId={legalData?.otherDocument?.tciDoumentId}
            onRemove={() => handleDeleteDocument(legalData.otherDocument, 'otherDocument')}
          />
        </Form.Item>

        <Row gutter={20}>
          <Col span={8}>
            <Form.Item className="m-0">
              <Button type="primary" htmlType="submit" size="large" loading={isLoadingSubmit} block>
                Kaydet ve Devam Et
              </Button>
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item className="m-0">
              <Button
                type="default"
                size="large"
                loading={isLoadingSubmit}
                onClick={onCancel}
                block
              >
                Kaydet ve Çık
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default LegalDocuments;
