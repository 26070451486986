import React, {useEffect, useState, VFC} from 'react';
import {Col, Row, Spin} from 'antd';
import {useHistory, useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import ContentContainer from '../../../components/ContentContainer';
import Icon from '../../../components/Icon';
import Text from '../../../components/Text';
import SaleInfoStatus from './SaleInfoStatus';
import customerApi from '../../../Api/Customer';
import searchApi from '../../../Api/Search';
import paths from '../../../routes/paths';
import {useAppSelector} from '../../../hooks/useRedux';
import {
  fetchCities,
  fetchCurrencyTypes,
  fetchPaymentTypes,
} from '../../../store/globals/globalsActions';
import {CREATE_SALES_FIELD_INFO} from '../../../constants';
import {setCustomerQueryData} from '../../../store/customer/customerActions';
import {ITaxOffice} from '../../../Api/Search/types';

const saleInfoStatusItems: {key: string; text: string; icon: string}[] = [
  {
    key: CREATE_SALES_FIELD_INFO.COMPANY_INFO.key,
    text: CREATE_SALES_FIELD_INFO.COMPANY_INFO.title,
    icon: CREATE_SALES_FIELD_INFO.COMPANY_INFO.icon,
  },
  {
    key: CREATE_SALES_FIELD_INFO.SALES_UNIT_WORK_CONDITION.key,
    text: CREATE_SALES_FIELD_INFO.SALES_UNIT_WORK_CONDITION.title,
    icon: CREATE_SALES_FIELD_INFO.SALES_UNIT_WORK_CONDITION.icon,
  },
  {
    key: CREATE_SALES_FIELD_INFO.COMPANY_IDENTIFIER_INFO.key,
    text: CREATE_SALES_FIELD_INFO.COMPANY_IDENTIFIER_INFO.title,
    icon: CREATE_SALES_FIELD_INFO.COMPANY_IDENTIFIER_INFO.icon,
  },
  {
    key: CREATE_SALES_FIELD_INFO.SALES_UNIT_FEEDBACK.key,
    text: CREATE_SALES_FIELD_INFO.SALES_UNIT_FEEDBACK.title,
    icon: CREATE_SALES_FIELD_INFO.SALES_UNIT_FEEDBACK.icon,
  },
];

const SaleDetail: VFC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams() as {taxNumber: string};
  const customer = useAppSelector((state: any) => state.customer.customerSale);
  const [isLoadingQueryCustomer, setIsLoadingQueryCustomer] = useState(false);
  const [isLoadingtaxOffices, setIsLoadingTaxOffices] = useState(false);
  const [taxOffices, setTaxOffices] = useState<ITaxOffice[]>([]);
  const [customerQueryData, setCustomerQuery] = useState<any>({});
  const {cities, isLoadingCities} = useAppSelector((state) => state.globals);

  const fetchTaxOffices = async (provinceCode: number) => {
    try {
      setIsLoadingTaxOffices(true);
      const taxOfficesData = await searchApi.fetchTaxOffices(provinceCode);
      setTaxOffices(taxOfficesData);
    } catch {
      setTaxOffices([]);
    } finally {
      setIsLoadingTaxOffices(false);
    }
  };

  const queryCustomer = async ({
    taxNumber,
    taxAdministration,
  }: {
    taxNumber: number;
    taxAdministration: string;
  }) => {
    try {
      setIsLoadingQueryCustomer(true);
      const taxOfficeData: any = taxOffices.find(({label}) => label === taxAdministration);
      const customerQuery = await customerApi.queryCustomer(
        taxNumber.toString(),
        taxOfficeData.label,
      );
      setCustomerQuery(customerQuery);
      dispatch(setCustomerQueryData(customerQuery));
    } finally {
      setIsLoadingQueryCustomer(false);
    }
  };

  useEffect(() => {
    if (taxOffices.length > 0 && Object.keys(customerQueryData).length <= 0) {
      queryCustomer(customer);
    }
  }, [taxOffices]);

  useEffect(() => {
    if (customer.province) {
      const found: any = cities.find((city) => city.label === customer.province);
      if (found) fetchTaxOffices(found.value);
    } else {
      history.push(paths.sales);
    }
  }, [customer]);

  useEffect(() => {
    dispatch(fetchCities());
    dispatch(fetchPaymentTypes());
    dispatch(fetchCurrencyTypes());
  }, []);

  const handleReport = () => {
    history.push(paths.getSalesDetailPath(params.taxNumber));
  };

  const handleStepKey = (key: any) => {
    dispatch(setCustomerQueryData({...customerQueryData, customerStepKey: key}));
    history.push(paths.salesCreate);
  };

  return (
    <Spin spinning={isLoadingQueryCustomer || isLoadingtaxOffices || isLoadingCities}>
      <ContentContainer>
        <Row className="mb-big" align="middle" gutter={20}>
          <Col>
            <Text type="h2">Müşteri Adayı</Text>
          </Col>

          <Col onClick={handleReport}>
            <Text type="h4" underlined>
              Detaylı Rapor Al <Icon icon="file-download" />
            </Text>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Text bold block>
              Unvanı:
            </Text>
            <Text>{customerQueryData?.title}</Text>
            <br />
            <br />
            <Text bold block>
              Vergi Kimlik No:
            </Text>
            <Text>{customer?.taxNumber}</Text>
            <br />
            <br />
            <Text bold block>
              Adres:
            </Text>
            <Text>{customerQueryData?.address}</Text>
            <br />
            <br />
          </Col>
          <Col span={12}>
            {saleInfoStatusItems.map(({key, text, icon}) => (
              <SaleInfoStatus
                className="mb hover-scale"
                key={key}
                icon={icon}
                text={text}
                onClick={() => handleStepKey(key)}
              />
            ))}
          </Col>
        </Row>
      </ContentContainer>
    </Spin>
  );
};

export default SaleDetail;
