import React, {useEffect} from 'react';

import Layout from './components/Layout';
import Content from './components/Layout/Content';
import Header from './components/Layout/Header';

import GlobalStyles from './GlobalStyles';
import ThemeProvider from './theme/ThemeProvider';
import 'antd/dist/antd.less';
import Routes from './routes';
import {useAppDispatch, useAppSelector} from './hooks/useRedux';
import {fetchUser} from './store/user/userActions';

const App: React.VFC = () => {
  const isLoggedIn = useAppSelector(({user}) => user.isLoggedIn);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchUser());
    }
  }, [dispatch]);

  return (
    <ThemeProvider>
      <GlobalStyles />

      <Layout>
        <Header />
        <Content>
          <Routes />
        </Content>
      </Layout>
    </ThemeProvider>
  );
};

export default App;
