import React, {useState} from 'react';
import {Tabs, Form, Row, Col} from 'antd';
import ContentContainer from '../../../../../components/ContentContainer';
// import Text from '../../../../../components/Text';
import InputPrice from '../../../../../components/InputPrice';

const {TabPane} = Tabs;

const ExcelYearPopup = () => {
  const [form] = Form.useForm();
  const [activeTab, setActiveTab] = useState('1');

  return (
    <ContentContainer horizontalPadding>
      <Form
        form={form}
        name="findeksInfo"
        layout="vertical"
        requiredMark={false}
        // initialValues={{company: customer.title}}
        // onFinish={handleSubmit}
      >
        <Tabs defaultActiveKey={activeTab} onChange={setActiveTab}>
          <TabPane tab="2021" key="1">
            <Row>
              <Col span={10}>
                <Form.Item name="company" label="Firma Adı">
                  <InputPrice displayPrefix={false} addonAfter="TL" placeholder="Örn. 123.234,00" />
                </Form.Item>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="2019" key="2">
            <Row>
              <Col span={10}>
                <Form.Item name="company" label="Firma Adı">
                  <InputPrice displayPrefix={false} addonAfter="TL" placeholder="Örn. 123.234,00" />
                </Form.Item>
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </Form>
    </ContentContainer>
  );
};

export default ExcelYearPopup;
