import React, {FC, useEffect, useState} from 'react';
import {Form, Input, Row, Col, Checkbox, notification} from 'antd';
import userApi from '../../../Api/User';
import Button from '../../../components/Button';
import Text from '../../../components/Text';
import ContentContainer from '../../../components/ContentContainer';
import {ValidatePhoneNo} from '../../../utils/string';
import {IUSERROLE, USERROLE} from '../../../constants';

const CreateUser: FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [usersRoles, setUsersRoles] = useState<any>();
  const [form] = Form.useForm();

  const handleSaleField = async (values: any) => {
    try {
      setIsLoading(true);
      const userResponse = await userApi.createUser({
        fullName: values.fullName,
        email: values.email,
        phoneNumber: values.phoneNo,
        active: true,
        role: values.role.join(', '),
      });
      if (userResponse) {
        setIsLoading(false);
        notification.success({message: 'İşlem Başarılı'});
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchRoles = async () => {
    try {
      setIsLoading(true);
      const userRoles = await userApi.fetchUserRoles();
      setUsersRoles(userRoles);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  return (
    <ContentContainer horizontalPadding>
      <Row style={{flexWrap: 'nowrap', justifyContent: 'center'}} gutter={40}>
        <Col flex={1} span={9}>
          <Text type="h2" bold>
            Kullanıcı Bilgiler
          </Text>
          <ContentContainer maxWidth={400}>
            <Form
              form={form}
              onFinish={handleSaleField}
              style={{marginTop: '2em'}}
              layout="vertical"
              requiredMark={false}
            >
              <Form.Item name="fullName" label="İsim - Soyisim" rules={[{required: true}]}>
                <Input type="text" />
              </Form.Item>

              <Form.Item
                name="email"
                label="E-posta Adresi"
                rules={[
                  {type: 'email', message: 'Lütfen geçerli bir e-posta adresi giriniz!'},
                  {
                    required: true,
                    message: 'Lütfen e-posta adresi giriniz!',
                  },
                ]}
              >
                <Input placeholder="E-posta Adresi" />
              </Form.Item>

              <Form.Item
                name="phoneNo"
                label="Telefon Numarası"
                rules={[
                  {
                    message: 'Lütfen telefon numaranızı giriniz!',
                    required: true,
                    validator: (_, value) => ValidatePhoneNo(value),
                  },
                ]}
              >
                <Input addonBefore="+90" placeholder="Telefon Numarası" />
              </Form.Item>

              <Form.Item name="role" label="Kullanıcı Yetkileri">
                <Checkbox.Group>
                  <Row>
                    {usersRoles?.length > 0 &&
                      usersRoles.map((role: IUSERROLE) => (
                        <Col span={15}>
                          <Checkbox value={role} style={{lineHeight: '32px'}}>
                            {USERROLE[role]}
                          </Checkbox>
                        </Col>
                      ))}
                  </Row>
                </Checkbox.Group>
              </Form.Item>

              <Form.Item className="m-0">
                <Button
                  className="mt"
                  disabled={isLoading}
                  loading={isLoading}
                  type="primary"
                  htmlType="submit"
                  size="large"
                  block
                >
                  Kullanıcı Oluştur
                </Button>
              </Form.Item>
            </Form>
          </ContentContainer>
        </Col>
      </Row>
    </ContentContainer>
  );
};

export default CreateUser;
