import React, {useEffect, useState, VFC} from 'react';
import {Button, Input, notification} from 'antd';
import {useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import ContentContainer from '../../../components/ContentContainer';
import Text from '../../../components/Text';
import Table from '../../../components/Table';
import PATHS from '../../../routes/paths';
import customerApi from '../../../Api/Customer';
import {ICustomer} from '../../../Api/Customer/types';
import Icon from '../../../components/Icon';
import {setCustomerSaleData} from '../../../store/customer/customerActions';

const SalesList: VFC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoadingCustomers, setIsLoadingCustomers] = useState(true);
  const [customers, setCustomers] = useState<ICustomer[]>([]);
  const [customerCount, setCustomerCount] = useState(0);
  const [pagingPage, setPagingPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [deleteKey, setDeleteKey] = useState(-1);

  const handleDelete = (record: any, index: any) => {
    setDeleteKey(index);
    console.log(record, 'record');
  };

  const deletConfirm = () => {
    console.log('Delete record');
  };

  const routeToCustomerDetail = (taxNumber: number, customer: any) => {
    if (!taxNumber) {
      notification.warning({
        message: 'Eksik Bilgi',
        description: 'Müşterinin VKN/TCKN ve Vergi Dairesi bilgilerini kontrol ediniz.',
      });
    } else {
      dispatch(setCustomerSaleData(customer));
      history.push(PATHS.getSalesDetailPath(taxNumber));
    }
  };

  const renderCol = (value: any, record: any) => (
    <div
      style={{display: 'block'}}
      role="button"
      tabIndex={-1}
      aria-hidden="true"
      onClick={() => routeToCustomerDetail(record.taxNumber, record)}
    >
      {value}
    </div>
  );

  const tableCols = [
    {
      title: 'Müşteri Adı',
      dataIndex: 'contactFullname',
      key: 'contactFullname',
      render: (value: any, record: any) => renderCol(value, record),
    },
    {
      title: 'Vergi Kimlik No',
      dataIndex: 'taxAdministration',
      key: 'taxAdministration',
      render: (value: any, record: any) => renderCol(value, record),
    },
    {
      title: 'Vergi Dairesi',
      dataIndex: 'taxNumber',
      key: 'taxNumber',
      render: (value: any, record: any) => renderCol(value, record),
    },
    {
      title: 'Şehir',
      dataIndex: 'province',
      key: 'province',
      render: (value: any, record: any) => renderCol(value, record),
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (value: string, record: any, index: any) => (
        <div style={{zIndex: 10}}>
          <div
            role="button"
            aria-hidden="true"
            tabIndex={0}
            onClick={() => handleDelete(record, index)}
          >
            <Icon size="lg" color="red" icon="trash-alt" />
          </div>
          {deleteKey === index && (
            <div className="delete-popup">
              <Text type="h5">Satırı silmek istediğinden emin misin?</Text>
              <div className="desc-btns">
                <Button onClick={() => setDeleteKey(-1)} type="default" className="mr">
                  Vazgeç
                </Button>
                <Button onClick={deletConfirm} style={{backgroundColor: 'red'}} type="primary">
                  Evet Sil
                </Button>
              </div>
            </div>
          )}
        </div>
      ),
    },
  ];

  const fetchCustomers = async (search = '', page = 1) => {
    try {
      setIsLoadingCustomers(true);
      const customersData = await customerApi.fetchCustomerSales({search, page});
      setCustomers(customersData.data);
      setCustomerCount(customersData.totalDataCount);
    } finally {
      setIsLoadingCustomers(false);
    }
  };

  const searchCustomer = (text: string) => {
    setSearchText(text);
    setPagingPage(1);
    fetchCustomers(text, 1);
  };
  const onChangePage = (page: number) => {
    setPagingPage(page);
    fetchCustomers(searchText, page);
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  return (
    <ContentContainer maxWidth={1125}>
      <Text type="h2">Müşteri Adayları</Text>
      <div className="search-input">
        <Input
          style={{marginTop: '1em', marginBottom: '2em'}}
          onChange={(e) => searchCustomer(e.target.value)}
          placeholder="Müşteri ara"
          prefix={<Icon size="lg" color="defaultSmoke" icon="search" />}
          suffix={<Button type="primary">Ara</Button>}
        />
      </div>

      <Table
        className="mt cursor-pointer"
        rowKey="id"
        dataSource={customers}
        pagination={{
          total: customerCount,
          current: pagingPage,
          onChange: onChangePage,
        }}
        columns={tableCols}
        loading={isLoadingCustomers}
      />
    </ContentContainer>
  );
};

export default SalesList;
