/* eslint-disable func-names */
/* eslint-disable no-plusplus */
/* eslint-disable no-bitwise */
/* eslint-disable import/no-cycle */
import html2canvas from 'html2canvas';
import {Buffer} from 'buffer';
import {IApiImage} from '../Api/types';
import searchApi from '../Api/Search';

/* eslint-disable import/prefer-default-export */
export const urlToFile = async (url = '', name = 'image', extension = '.pdf') => {
  const response = await fetch(url);
  // here image is url/location of image
  const blob = await response.blob();
  const file = new File([blob], `${name}${extension}`, {type: blob.type});

  return file;
};

export const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

export const getBase64 = (file: any): Promise<IApiImage> => {
  return new Promise((resolve, reject) => {
    const filename = file?.name || 'dosya';
    const reader = new FileReader();
    reader.onload = function () {
      // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
      let encoded: any = reader.result!.toString().replace(/^data:(.*,)?/, '');
      if (encoded.length % 4 > 0) {
        encoded += '='.repeat(4 - (encoded.length % 4));
      }

      resolve({filename, base64FileContent: encoded});
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
      reject(error);
    };
    reader.readAsDataURL(file);
  });
};

export function dataURLtoFile(streamData: string, filename: string) {
  let b64 = Buffer.from(streamData).toString('base64');
  b64 = `data:applicatioin/pdf;base64,${b64}`;

  const arr = b64.split(',');
  // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
  const mime = arr[0].match(/:(.*?);/)![1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {type: mime});
}

export function convertFileToAntdFile(file: File) {
  return {...file, originFileObj: file};
}

export const download = function (link: string, filename = 'tci') {
  const linkElement = document.createElement('a');
  linkElement.download = filename;
  linkElement.href = link;
  linkElement.click();
};

export const dowloadDivAsImage = (divId = '', fileTitle = 'tci-customer') => {
  const content = document.querySelector(`#${divId}`) as HTMLElement;

  html2canvas(content).then((canvas) => {
    const link = canvas.toDataURL();
    download(link, fileTitle);
  });
};

export function s2ab(s: string) {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
}

export function createExcelFile(data: string) {
  const blob = new Blob([Buffer.from(data, 'base64')], {
    type: '',
  });

  return URL.createObjectURL(blob);
}

export const FilePromise = (fileDocumnet: any) => {
  return new Promise((resolve) => {
    searchApi.fetchDocument(fileDocumnet.tciDoumentId).then((_file) => {
      const name = fileDocumnet?.fileName;
      const renamedFile = new File([_file], name);
      resolve(renamedFile);
    });
  });
};
