import React, {FC, useEffect, useState} from 'react';
import {Col, notification, Row} from 'antd';
import {useLocation} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import ContentContainer from '../../../components/ContentContainer';
import Sider from '../../../components/Layout/Sider';
import {CREATE_SALES_FIELD_INFO} from '../../../constants';
import {resetCustomerQueryData} from '../../../store/customer/customerActions';
import customerApi from '../../../Api/Customer';
import SaleFieldInfo from './SaleFieldInfo';
import SaleUnitWork from './SalesUnitWork';
import CompanyInfo from './CompanyInfo';
import CompanyIdentifyInfo from './CompanyIdentifyInfo';
import SalesUnitFeedback from './SalesUnitFeedback';
import {useAppSelector} from '../../../hooks/useRedux';
import {customerSaleData} from './data';

const CreateSales = () => {
  const dispatch = useDispatch();
  const location = useLocation<{activeTabKey: string}>();
  const [activeSiderKey, setActiveSiderKey] = useState('SALES_FIELD_INFO');
  const [customerData, setCustomerData] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const customer = useAppSelector((state: any) => state.customer.customerCreateQueryData);
  const customerSale = useAppSelector((state: any) => state.customer.customerSale);

  const customerAccountId = customer.accountId;

  const siderItems = [
    {
      key: CREATE_SALES_FIELD_INFO.SALES_FIELD_INFO.key,
      text: `${CREATE_SALES_FIELD_INFO.SALES_FIELD_INFO.title} (Mecburi)`,
      icon: CREATE_SALES_FIELD_INFO.SALES_FIELD_INFO.icon,
      component: SaleFieldInfo,
      disabled: isLoading || customerAccountId,
    },
    {
      key: CREATE_SALES_FIELD_INFO.COMPANY_INFO.key,
      text: `${CREATE_SALES_FIELD_INFO.COMPANY_INFO.title}`,
      icon: CREATE_SALES_FIELD_INFO.COMPANY_INFO.icon,
      component: CompanyInfo,
      disabled: !customerAccountId || isLoading,
    },
    {
      key: CREATE_SALES_FIELD_INFO.SALES_UNIT_WORK_CONDITION.key,
      text: `${CREATE_SALES_FIELD_INFO.SALES_UNIT_WORK_CONDITION.title} (Mecburi)`,
      icon: CREATE_SALES_FIELD_INFO.SALES_UNIT_WORK_CONDITION.icon,
      component: SaleUnitWork,
      disabled: !customerAccountId || isLoading,
    },
    {
      key: CREATE_SALES_FIELD_INFO.COMPANY_IDENTIFIER_INFO.key,
      text: CREATE_SALES_FIELD_INFO.COMPANY_IDENTIFIER_INFO.title,
      icon: CREATE_SALES_FIELD_INFO.COMPANY_IDENTIFIER_INFO.icon,
      component: CompanyIdentifyInfo,
      disabled: !customerAccountId || isLoading,
    },
    {
      key: CREATE_SALES_FIELD_INFO.SALES_UNIT_FEEDBACK.key,
      text: CREATE_SALES_FIELD_INFO.SALES_UNIT_FEEDBACK.title,
      icon: CREATE_SALES_FIELD_INFO.SALES_UNIT_FEEDBACK.icon,
      component: SalesUnitFeedback,
      disabled: !customerAccountId,
    },
  ];

  useEffect(() => {
    setCustomerData(customerSaleData);
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
  }, [activeSiderKey]);

  useEffect(() => {
    if (location.state?.activeTabKey) {
      setActiveSiderKey(location.state.activeTabKey);
    }
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetCustomerQueryData());
    };
  }, []);

  const getSiderItemByKey = (itemKey: string) => siderItems.find(({key}) => key === itemKey);
  const Component: FC<any> = getSiderItemByKey(activeSiderKey)?.component || SaleFieldInfo;

  const uploadCustomerData = async (currentIdx: any, newCustomerData: any) => {
    try {
      setIsLoading(true);
      const customersResponse = await customerApi.uploadCustomerSales({
        ...newCustomerData,
        accountId: customer ? customer.accountId : 0,
        formDate: newCustomerData.formDate ? newCustomerData.formDate.format() : '',
        expectedSalesTon: newCustomerData.expectedSalesTon
          ? parseInt(newCustomerData.expectedSalesTon, 10)
          : 0,
        expectedSalesTL: newCustomerData.expectedSalesTL
          ? parseInt(newCustomerData.expectedSalesTL, 10)
          : 0,
        requestedLimitAmount: newCustomerData.requestedLimitAmount
          ? parseInt(newCustomerData.requestedLimitAmount, 10)
          : 0,
        paymentMethodParameterId: newCustomerData.paymentMethodParameterId
          ? parseInt(newCustomerData.paymentMethodParameterId, 10)
          : 0,
        domesticEndorsement: newCustomerData.domesticEndorsement
          ? parseInt(newCustomerData.domesticEndorsement, 10)
          : 0,
        abroadEndorsement: newCustomerData.abroadEndorsement
          ? parseInt(newCustomerData.abroadEndorsement, 10)
          : 0,
      });
      if (customersResponse) {
        if (currentIdx === 4) notification.success({message: 'İşlem Başarılı'});
        setIsLoading(false);
      }
    } catch (e) {
      console.log('error', e);
      setIsLoading(false);
    }
  };

  const next = async (data: any) => {
    setCustomerData({...customerData, ...data});
    const currentIdx = siderItems.findIndex(({key}) => key === activeSiderKey);
    await uploadCustomerData(currentIdx, {...customerData, ...data});
    if (siderItems[currentIdx + 1] && siderItems[currentIdx + 1].key) {
      setActiveSiderKey(siderItems[currentIdx + 1].key);
    }
  };

  useEffect(() => {
    if (customer.customerStepKey && customerSale) {
      setActiveSiderKey(customer.customerStepKey);
      setCustomerData(customerSale);
    }
  }, [customer, customerSale]);

  const visitCustomers = () => {
    setCustomerData({});
  };

  return (
    <ContentContainer horizontalPadding>
      <Row style={{flexWrap: 'nowrap'}} gutter={40}>
        <Col>
          <Sider
            items={siderItems}
            activeKey={activeSiderKey}
            onChange={(itemKey) => setActiveSiderKey(itemKey)}
          />
        </Col>

        <Col flex={1}>
          <Component
            isLoading={isLoading}
            customerData={customerData}
            onSubmit={next}
            onCancel={visitCustomers}
          />
        </Col>
      </Row>
    </ContentContainer>
  );
};

export default CreateSales;
