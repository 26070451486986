import {tciApi, tciApiV2} from '..';
import {
  ICustomerFinancialData,
  ICustomerFinancialPOSTData,
  ICustomerFindeksData,
  ICustomerInsuranceData,
  ICustomerLegalDocumentData,
  ICustomerQuery,
  ICustomerRecordData,
  ICustomerRecordPOSTData,
  ICustomersResponse,
  ICustomerWarrantData,
  ICustomerWarrantPOSTData,
  ICustomerWorkingConditionsData,
  ICustomerWorkingConditionsPOSTData,
} from './types';

export default {
  fetchCustomers({
    search = undefined,
    page = 1,
    size = 10,
  }: {
    search?: string;
    page?: number;
    size?: number;
  } = {}): Promise<ICustomersResponse> {
    return tciApi.get('/account', {params: {search, page, size}});
  },

  deleteCustomers(accountId: any): Promise<ICustomersResponse> {
    return tciApiV2.delete(`/account/${accountId}`);
  },

  downloadCustomerExcel(): Promise<any> {
    return tciApi.downloadFile('account/import/template');
  },
  uploadCustomerExcel(formData: FormData): Promise<number> {
    return tciApi.post('/account/import', formData);
  },

  queryCustomer(taxNumber: string, taxOfficeName?: string): Promise<ICustomerQuery> {
    const idKey = taxNumber.toString.length === 11 ? 'Tckn' : 'Vkn';
    return tciApi.post('/autotax/query', {
      [idKey]: taxNumber,
      vergiDairesi: taxOfficeName,
    });
  },

  fetchCreateStepStatuses(accountId: number): Promise<Record<number, boolean>> {
    return tciApi.get(`/account/${accountId}/stepinformation`);
  },

  fetchCustomerRecordInfo(id: number): Promise<ICustomerRecordData> {
    return tciApi.get(`/account/${id}/wizard/step1`);
  },
  uploadCustomerRecordInfo(
    customerData: ICustomerRecordPOSTData,
  ): Promise<ICustomerRecordPOSTData> {
    return tciApi.post(`/account/wizard/step1`, customerData);
  },

  fetchCustomerWarrantInfo(id: number): Promise<ICustomerWarrantData> {
    return tciApi.get(`/account/${id}/wizard/step2`);
  },

  fetchFactoringData(id: number): Promise<ICustomerWarrantData> {
    return tciApiV2.get(`/Account/${id}/factoring`);
  },
  fetchAkreditifData(id: number): Promise<ICustomerWarrantData> {
    return tciApiV2.get(`/Akreditif/${id}`);
  },
  fetchInsuranceRatingData(id: number): Promise<ICustomerWarrantData> {
    return tciApiV2.get(`/InsuranceRating/${id}`);
  },
  fetchCustomerCiroInfoV2(id: number): Promise<ICustomerWorkingConditionsData> {
    return tciApiV2.get(`/Account/${id}/endorsement`);
  },
  fetchCustomerOtherInfoV2(id: number): Promise<ICustomerWorkingConditionsData> {
    return tciApiV2.get(`/Account/${id}/endorsement`);
  },
  updateFactoringData(id: number, factoringData: any): Promise<ICustomerWarrantPOSTData> {
    return tciApiV2.put(`/Account/${id}/factoring`, factoringData);
  },
  updateAkreditifData(id: number, akreditifData: any): Promise<ICustomerWarrantPOSTData> {
    return tciApiV2.put(`/Akreditif/${id}`, akreditifData);
  },
  updateInsuranceRatingData(
    id: number,
    insuranceRatingData: any,
  ): Promise<ICustomerWarrantPOSTData> {
    return tciApiV2.put(`/InsuranceRating/${id}`, insuranceRatingData);
  },

  uploadCustomerWarrantInfo(
    customerData: ICustomerWarrantPOSTData,
  ): Promise<ICustomerWarrantPOSTData> {
    return tciApi.post(`/account/wizard/step2`, customerData);
  },

  fetchCustomerInsuranceInfo(id: number): Promise<ICustomerInsuranceData> {
    return tciApi.get(`/account/${id}/wizard/step3`);
  },
  uploadCustomerInsuranceInfo(
    customerData: ICustomerInsuranceData,
  ): Promise<ICustomerInsuranceData> {
    return tciApi.post(`/account/wizard/step3`, customerData);
  },

  fetchCustomerFindeksInfo(id: number): Promise<ICustomerFindeksData> {
    return tciApi.get(`/account/${id}/wizard/step4`);
  },
  uploadCustomerFindeksInfo(customerData: ICustomerFindeksData): Promise<ICustomerFindeksData> {
    return tciApi.post(`/account/wizard/step4`, customerData);
  },

  fetchCustomerFinancialInfo(id: number): Promise<ICustomerFinancialData> {
    return tciApi.get(`/account/${id}/wizard/step5`);
  },
  uploadCustomerFinancialInfo(
    customerData: ICustomerFinancialPOSTData,
  ): Promise<ICustomerFinancialPOSTData> {
    return tciApi.post(`/account/wizard/step5`, customerData);
  },
  uploadCustomerFinancialTableInfo(formData: FormData, id: number): Promise<number> {
    return tciApiV2.post(`FinancialTableSummary/account/${id}/import`, formData);
  },

  fetchCustomerLegalDocumentInfo(id: number): Promise<ICustomerLegalDocumentData> {
    return tciApi.get(`/account/${id}/wizard/step6`);
  },
  uploadCustomerLegalDocumentInfo(
    customerData: ICustomerLegalDocumentData,
  ): Promise<ICustomerLegalDocumentData> {
    return tciApi.post(`/account/wizard/step6`, customerData);
  },

  fetchCustomerWorkingConditionsInfo(id: number): Promise<ICustomerWorkingConditionsData> {
    return tciApi.get(`/account/${id}/wizard/step7`);
  },
  uploadCustomerWorkingConditionsInfo(
    customerData: ICustomerWorkingConditionsPOSTData,
  ): Promise<ICustomerWorkingConditionsPOSTData> {
    return tciApi.post(`/account/wizard/step7`, customerData);
  },

  uploadCustomerSales(data: any): Promise<ICustomerQuery> {
    return tciApiV2.post('/Customer', data);
  },

  fetchCustomerSales({
    search = undefined,
    page = 1,
    size = 10,
  }: {
    search?: string;
    page?: number;
    size?: number;
  } = {}): Promise<ICustomersResponse> {
    return tciApiV2.get('/Customer', {params: {search, page, size}});
  },
};
