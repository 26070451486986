import React, {FC, useEffect, useState} from 'react';
import {Col, Form, Input, Row, Select, Spin} from 'antd';
import Button from '../../../../components/Button';
import Text from '../../../../components/Text';
import ProgressStep from '../../../../components/ProgressStep';
import {ICustomerCreateComponent} from '../types';
import {useAppDispatch, useAppSelector} from '../../../../hooks/useRedux';
import customerApi from '../../../../Api/Customer';
import {
  fetchCurrencyTypes,
  fetchLimitRateTypes,
  fetchPaymentTypes,
  fetchTermOfSaleTypes,
} from '../../../../store/globals/globalsActions';
import {CURRENCY} from '../../../../constants';
import {convertDotSeperatedToFloat, convertNumberDotSeperated} from '../../../../utils/string';
import {SummaryCol, SummaryRow} from './styles';
import {ICustomerWorkingConditionsData} from '../../../../Api/Customer/types';
import InputPrice from '../../../../components/InputPrice';
import {setInsuranceRatingData} from '../../../../store/customer/customerActions';

const WorkingConditionsInfo: FC<ICustomerCreateComponent> = ({onSubmit, onCancel}) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const [currency, setCurrency] = useState<number>(CURRENCY.TL.value);
  const [ciroData, setCiroData] = useState<any>();
  const [summaryData, setSummaryData] =
    useState<ICustomerWorkingConditionsData['guaranteeSummary']>();
  const [isLoadingInitialData, setIsLoadingInitialData] = useState(true);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const {customerCreateQueryData: customer, insuranceRating} = useAppSelector(
    (state) => state.customer as any,
  );
  const {
    currencyTypes,
    isLoadingCurrencyTypes,
    termOfSaleTypes,
    isLoadingTermOfSaleTypes,
    limitRateTypes,
    isLoadingLimitRateTypes,
    paymentTypes,
    isLoadingPaymentTypes,
  } = useAppSelector((state) => state.globals);

  const fetchInitialData = async () => {
    dispatch(setInsuranceRatingData({}));
    const insuranceRatingData: any = await customerApi.fetchInsuranceRatingData(customer.accountId);
    insuranceRatingData && dispatch(setInsuranceRatingData(insuranceRatingData[0]));
    const ciroRes: any = await customerApi.fetchCustomerCiroInfoV2(customer.accountId);
    ciroRes?.length > 0 && setCiroData(ciroRes[0]);
    const initialData = await customerApi.fetchCustomerWorkingConditionsInfo(customer.accountId);
    const initalValues = initialData.salesCondition || {};
    setCurrency(initalValues.limitAmountCurrencyParamterId || CURRENCY.TL.value);
    setSummaryData(initialData.guaranteeSummary);
    form.setFieldsValue({
      monthOfTermCount:
        ciroRes?.length > 0 ? ciroRes[0].acceptedTerm : initalValues.monthOfTermCount,
      limitGuaranteeCoefficientParamterId: initalValues.limitGuaranteeCoefficientParamterId,
      limitAmount: convertNumberDotSeperated(initalValues.limitAmount),
      paymentMethodParameterId: initalValues.paymentMethodParameterId,
    });
    setIsLoadingInitialData(false);
  };
  const [last4Years] = useState(
    [...new Array(4)].map((_, idx) => new Date().getFullYear() - idx - 1),
  );

  const getMonthofTerm = (value: any) => {
    if (value === 'Peşin') {
      return 1;
    }
    const label =
      (termOfSaleTypes.length > 0 && termOfSaleTypes.find((term) => term.value === value)?.label) ||
      0;
    return parseInt(`${label}`, 10);
  };

  const handleOnSubmit = async (formValues: any) => {
    try {
      setIsLoadingSubmit(true);
      const {
        monthOfTermCount,
        limitGuaranteeCoefficientParamterId,
        limitAmount,
        paymentMethodParameterId,
      } = formValues;

      await customerApi.uploadCustomerWorkingConditionsInfo({
        accountId: customer.accountId,
        salesCondition: {
          monthOfTermCount: getMonthofTerm(monthOfTermCount),
          limitGuaranteeCoefficientParamterId,
          limitAmount: convertDotSeperatedToFloat(limitAmount),
          limitAmountCurrencyParamterId: currency,
          paymentMethodParameterId,
        },
      });
      onSubmit();
    } catch {
      setIsLoadingSubmit(false);
    }
  };

  useEffect(() => {
    fetchInitialData();
    dispatch(fetchCurrencyTypes());
    dispatch(fetchPaymentTypes());
    dispatch(fetchTermOfSaleTypes());
    dispatch(fetchLimitRateTypes());
  }, []);

  const SelectCurrencyType = (
    <Select
      value={isLoadingCurrencyTypes ? undefined : currency}
      onSelect={(val: number) => setCurrency(val)}
      options={currencyTypes}
      loading={isLoadingCurrencyTypes}
      dropdownMatchSelectWidth={false}
    />
  );

  return (
    <Spin spinning={isLoadingInitialData}>
      <ProgressStep className="mb-big" title="Çalışma Koşulları" value={8} steps={8} />
      <Text type="h2" bold>
        Çalışma Koşulları
      </Text>

      <Form
        form={form}
        name="workingConditionsInfo"
        layout="vertical"
        requiredMark={false}
        initialValues={{company: customer.title}}
        onFinish={handleOnSubmit}
      >
        <Row>
          <Col span={18}>
            <Form.Item name="company" label="Firma Adı">
              <Input disabled />
            </Form.Item>

            <SummaryRow className="my-big">
              <SummaryCol className="smoke" span={8}>
                <Text align="center" bold block>
                  Alacak Sigortası
                </Text>
                <Text align="center" block>
                  {convertNumberDotSeperated(summaryData?.creditInsurance)} TL
                </Text>
              </SummaryCol>
              <SummaryCol className="smoke" span={8}>
                <Text align="center" bold block>
                  Teminat Mektubu
                </Text>
                <Text align="center" block>
                  {convertNumberDotSeperated(summaryData?.guaranteeLetter)} TL
                </Text>
              </SummaryCol>
              <SummaryCol className="smoke" span={8}>
                <Text align="center" bold block>
                  DBS
                </Text>
                <Text align="center" block>
                  {convertNumberDotSeperated(summaryData?.dbs)} TL
                </Text>
              </SummaryCol>

              <SummaryCol span={8}>
                <Text align="center" bold block>
                  İpotek
                </Text>
                <Text align="center" block>
                  {convertNumberDotSeperated(summaryData?.lien)} TL
                </Text>
              </SummaryCol>
              <SummaryCol span={8}>
                <Text align="center" bold block>
                  Diğer
                </Text>
                <Text align="center" block>
                  {convertNumberDotSeperated(summaryData?.other)} TL
                </Text>
              </SummaryCol>
              <SummaryCol span={8}>
                <Text align="center" bold block>
                  Toplam
                </Text>
                <Text align="center" block>
                  {convertNumberDotSeperated(summaryData?.total)} TL
                </Text>
              </SummaryCol>
            </SummaryRow>

            <SummaryRow className="my-big">
              {last4Years.map((year) => (
                <SummaryCol className="smoke" span={6}>
                  <Text align="center" bold block>
                    {year} {year === 2022 ? 'YTD' : ''} Ciro
                  </Text>
                  <Text align="center" block>
                    {ciroData && convertNumberDotSeperated(ciroData[`endorsement${year}`])} TL
                  </Text>
                </SummaryCol>
              ))}

              <SummaryCol span={6}>
                <Text align="center" bold block>
                  Açik Hesap Bakiyesi
                </Text>
                <Text align="center" block>
                  {convertNumberDotSeperated(insuranceRating?.acikHesapBakiyesi)} TL
                </Text>
              </SummaryCol>
              <SummaryCol span={6}>
                <Text align="center" bold block>
                  Vadesi Gelmemiş Çek
                </Text>
                <Text align="center" block>
                  {convertNumberDotSeperated(insuranceRating?.vadesiGelmemisCek)} TL
                </Text>
              </SummaryCol>
              <SummaryCol span={6}>
                <Text align="center" bold block>
                  Toplam Alacak
                </Text>
                <Text align="center" block>
                  {convertNumberDotSeperated(insuranceRating?.toplamAlacak)} TL
                </Text>
              </SummaryCol>
              <SummaryCol span={6}>
                <Text align="center" bold block>
                  Bekleyen Sipariş
                </Text>
                <Text align="center" block>
                  {convertNumberDotSeperated(insuranceRating?.bekleyenSiparis)} TL
                </Text>
              </SummaryCol>
            </SummaryRow>

            <Form.Item name="monthOfTermCount" label="Satış Vadesi (Gün)">
              <Select options={termOfSaleTypes} loading={isLoadingTermOfSaleTypes} />
            </Form.Item>

            <Form.Item name="limitGuaranteeCoefficientParamterId" label="Limit Teminat Kat Sayısı">
              <Select options={limitRateTypes} loading={isLoadingLimitRateTypes} />
            </Form.Item>

            <Form.Item name="limitAmount" label="Limit">
              <InputPrice displayPrefix={false} addonAfter={SelectCurrencyType} />
            </Form.Item>

            <Form.Item name="paymentMethodParameterId" label="Ödeme Şekli">
              <Select options={paymentTypes} loading={isLoadingPaymentTypes} />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={9}>
            <Form.Item className="m-0">
              <Button type="primary" htmlType="submit" size="large" loading={isLoadingSubmit} block>
                Kaydet ve İlerle
              </Button>
            </Form.Item>
          </Col>
          <Col span={7} className="pl">
            <Form.Item className="m-0">
              <Button
                type="default"
                size="large"
                loading={isLoadingSubmit}
                onClick={onCancel}
                block
              >
                Kaydet ve Çık
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default WorkingConditionsInfo;
