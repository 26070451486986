import React, {FC, useEffect, useState} from 'react';
import {Col, Drawer, Form, Input, notification, Row, Spin} from 'antd';
import Button from '../../../../components/Button';
import Text from '../../../../components/Text';
import Upload from '../../../../components/Upload';
import ProgressStep from '../../../../components/ProgressStep';
import {convertFileToAntdFile, getBase64, normFile} from '../../../../utils/file';
import {ICustomerCreateComponent} from '../types';
import {useAppSelector} from '../../../../hooks/useRedux';
import customerApi from '../../../../Api/Customer';
import searchApi from '../../../../Api/Search';
import {IApiImage} from '../../../../Api/types';
import ExcelYearPopup from './ExcelYearPopup';

const FinancialInfo: FC<ICustomerCreateComponent> = ({onSubmit, onCancel}) => {
  const [form] = Form.useForm();
  const [isLoadingInitialData, setIsLoadingInitialData] = useState(true);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [last3Years] = useState([...new Array(3)].map((_, idx) => new Date().getFullYear() - idx));
  const [yearFiles, setyearFiles] = useState<any>(null);

  const customer = useAppSelector((state) => state.customer.customerCreateQueryData);
  const [financialData, setFinancialData] = useState<any>(null);

  const fetchInitialData = async () => {
    const initialData = await customerApi.fetchCustomerFinancialInfo(customer.accountId);
    const years = Object.keys(initialData.documentIdByYear);
    setFinancialData(initialData);

    const FilePromise = (fileDocumnet: any, year: any) => {
      return new Promise((resolve) => {
        searchApi.fetchDocument(fileDocumnet).then((_file) => {
          const name = `${year}.pdf`;
          const renamedFile = new File([_file], name);
          resolve(renamedFile);
        });
      });
    };

    const documents = await Promise.all(
      years.map((year) => FilePromise(initialData.documentIdByYear[year], year)),
    );
    const files = documents.map((file) => convertFileToAntdFile(file as File));

    years.map((year, idx) => {
      return form.setFieldsValue({[year]: files[idx] ? [files[idx]] : []});
    });
    setIsLoadingInitialData(false);
  };

  const handleSubmit = async (values: any) => {
    try {
      setIsLoadingSubmit(true);
      const uploads = (await Promise.all(
        last3Years.map(
          (year) => values[year]?.[0]?.originFileObj && getBase64(values[year]?.[0]?.originFileObj),
        ),
      )) as (IApiImage | undefined)[];
      console.log(values);
      await customerApi.uploadCustomerFinancialInfo({
        accountId: customer.accountId,
        financialStatements: uploads.map((upload, idx) => ({
          year: last3Years[idx],
          document: upload,
        })),
      });

      onSubmit();
    } catch {
      setIsLoadingSubmit(false);
    }
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  const handleDeleteDocument = (file: any, key: any) => {
    searchApi.deleteDocument(file).then((res) => {
      console.log(res, 'res');
      notification.success({message: 'İşlem Başarılı', description: 'Dosya silindi yanıtı'});
      form.setFieldsValue({[key]: []});
    });
  };

  const onUploadFile = async (fileYear: any, year: any) => {
    setyearFiles({...yearFiles, [year]: fileYear});
    const formData = new FormData();
    formData.append('file', fileYear);
    const response = await customerApi.uploadCustomerFinancialTableInfo(
      formData,
      customer.accountId,
    );
    console.log(response, 'response');
  };

  const handleDeleteYearFile = (year: any) => {
    setyearFiles({...yearFiles, [year]: null});
  };

  return (
    <Spin spinning={isLoadingInitialData}>
      <ProgressStep className="mb-big" title="Mali Tablolar" value={6} steps={8} />

      <Form
        form={form}
        name="financialInfo"
        layout="vertical"
        requiredMark={false}
        initialValues={{company: customer.title}}
        onFinish={handleSubmit}
      >
        <Row>
          <Col span={10}>
            <Form.Item name="company" label="Firma Adı">
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>

        <Text className="m-0" type="h3" bold>
          Kurumlar Vergisi Beyannamesi (pdf)
        </Text>
        <Text>Son 3 yıla ait Kurumlar Vergisi Beyannamesi bilgilerini yükleyin.</Text>

        <Row className="my-big" gutter={20}>
          {last3Years.map((year) => (
            <Col key={year}>
              <Form.Item name={year} valuePropName="fileList" getValueFromEvent={normFile}>
                <Upload
                  accept=".doc,.docx,application/pdf"
                  documentId={financialData?.documentIdByYear[year]}
                  label="PDF Yükle"
                  desc={`${year} yılı`}
                  onRemove={() => handleDeleteDocument(financialData?.documentIdByYear[year], year)}
                />
              </Form.Item>
            </Col>
          ))}
          <Col span={15}>
            <Form.Item className="m-0">
              <Button
                className="mali-btn"
                type="default"
                htmlType="submit"
                size="large"
                loading={isLoadingSubmit}
                block
              >
                Tabloları Oku ve Analiz Et
              </Button>
            </Form.Item>
          </Col>
        </Row>

        <Text className="m-0 mt" type="h3" bold>
          Gelir Tablosu ve Bilanço Ekle
        </Text>
        <Text className="m-0 mt" type="h4" style={{fontSize: 16}}>
          Son 3 yıla ait Gelir Tablosu ve Bilanço Ekleyin. JPEG veya Excel yükleyebilirsiniz.
        </Text>
        <Text type="h4" style={{fontSize: 16}}>
          JPEG olarak yüklerseniz bu verileri analiz edemezsiniz.
        </Text>

        <Row className="my-big" gutter={20}>
          {last3Years.map((year) => (
            <Col style={{marginBottom: 25}} key={year}>
              <Upload
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, image/jpeg, image/png"
                fileList={yearFiles && yearFiles !== {} && yearFiles[year] ? [yearFiles[year]] : []}
                onChange={(val: any) => onUploadFile(val.file, year)}
                label="Excel /JPEG Yükle"
                desc={`${year} yılı`}
                onRemove={() => handleDeleteYearFile(year)}
              />
            </Col>
          ))}
          <Col span={15}>
            <Form.Item className="m-0">
              <Button
                className="mali-btn"
                type="default"
                onClick={() => setIsDrawerVisible(!isDrawerVisible)}
                size="large"
                loading={isLoadingSubmit}
                block
              >
                Gelir Tablosu ve bilanço Analiz Et
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Drawer
          title="Mali Veriler"
          width={600}
          visible={isDrawerVisible}
          onClose={() => setIsDrawerVisible(!isDrawerVisible)}
        >
          <ExcelYearPopup />
        </Drawer>
        <Row gutter={20}>
          <Col span={8}>
            <Form.Item className="m-0">
              <Button type="primary" htmlType="submit" size="large" loading={isLoadingSubmit} block>
                Kaydet ve İlerle
              </Button>
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item className="m-0">
              <Button
                type="default"
                size="large"
                loading={isLoadingSubmit}
                onClick={onCancel}
                block
              >
                Kaydet ve Çık
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default FinancialInfo;
