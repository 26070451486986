/* eslint-disable react/forbid-prop-types */
import React, {useEffect, useState} from 'react';
import {useTheme} from 'styled-components';
import moment from 'moment';
import {
  BarChart as RCBarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from 'recharts';
import {Spin} from 'antd';
import CustomTooltip from './CustomTooltip';
import {convertNumberDotSeperated} from '../../utils/string';

// TODO: DEMO ICIN LINECHARTTAN KOPYALANDI - REFACTOR

const BarChart = ({
  height,
  data,
  xKey,
  yBarsLeft,
  // yUnitLeft,
  yBarsRight,
  yUnitRight,
  // yMinLeft,
  // yMaxLeft,
  yMinRight,
  yMaxRight,
  tickFormatter,
  syncId,
  grid,
  title,
  loading,
}) => {
  const theme = useTheme();
  const [isDateX, setIsDateX] = useState(false); // decides format of the x-axis
  const [shouldDisplayTime, setShouldDisplayTime] = useState(false); // decides format of the x-axis
  const selectedKeys = [...yBarsLeft.map(({key}) => key), ...yBarsRight.map(({key}) => key)]; // lines will be visible if it is selected
  // const [isAnimationActive, setIsAnimationActive] = useState(true);

  useEffect(() => {
    if (data && xKey) {
      const isDate = !!(data[0] && moment(data[0][xKey]).isValid());
      setIsDateX(isDate);
      if (isDate) {
        const startDate = moment(data[0][xKey]);
        const endDate = moment(data[data.length - 1][xKey]);
        const dayDiff = Math.abs(startDate.diff(endDate, 'days'));
        setShouldDisplayTime(dayDiff <= 2);
      }
    }
  }, [xKey, data]);

  // const handleClickLegend = ({dataKey}) => {
  //   setSelectedKeys((prevState) => {
  //     if (prevState.includes(dataKey)) {
  //       return prevState.filter((key) => key !== dataKey);
  //     }
  //     return [...prevState, dataKey];
  //   });
  // };

  const getXFormat = () => {
    if (isDateX) {
      if (shouldDisplayTime) {
        return (tick) => {
          const date = moment(tick);
          return date.isValid() ? date.format('HH:mm:ss   ') : '';
        };
      }
      return (tick) => {
        const date = moment(tick);
        return date.isValid() ? date.format('YYYY') : '';
      };
    }
    return tickFormatter;
  };

  const getAllBars = () => [
    ...yBarsLeft.map((options) => ({...options, yAxisId: 'left'})),
    ...yBarsRight.map((options) => ({...options, yAxisId: 'right'})),
  ];

  if (loading) {
    return (
      <Spin spinning>
        <div style={{height}} />
      </Spin>
    );
  }

  return (
    <div>
      {title && <h2 className="primary mx mb-big">{title}</h2>}
      <ResponsiveContainer height={height}>
        <RCBarChart data={data.length ? data : [{}]} syncId={syncId || null}>
          {grid && (
            <CartesianGrid
              strokeDasharray="3 3"
              vertical={false}
              stroke={theme.main.spaceColorFaded}
            />
          )}
          <XAxis dataKey={xKey} tickFormatter={getXFormat()} />

          {/* {getAllBars().map(({ color }, idx) => (
            <defs key={`linear-gradient-${idx}`}>
              <linearGradient id={`linear-gradient-${chartId}-${idx}`} x1="0" y1="0" x2="0" y2="1">
                <stop offset="20%" stopColor={getChartColor(color)} stopOpacity={1} />
                <stop offset="100%" stopColor={getChartColor(color)} stopOpacity={0} />
              </linearGradient>
            </defs>
          ))} */}

          {/* MORE THAN ONE LINE CAN BE ON A Y-AXIS [ LEFT - RIGHT ] */}

          {yBarsRight.length && (
            <YAxis
              unit={yUnitRight}
              orientation="right"
              yAxisId="right"
              domain={[yMinRight, yMaxRight]}
            />
          )}

          {getAllBars().map(({key, color, displayKey, yAxisId, tip, calculateColor}, idx) => (
            <Bar
              key={`line-${idx}`}
              name={displayKey || key}
              dataKey={key}
              fill={(color && theme.getThemedColor(color)) || theme.main.primaryColor} // REQUIRED FOR DECIDING COLORS OF TAG IN THE LEGEND - CELL(BARS) COMPONENT DESIDES ITS OWN COLOR
              opacity={selectedKeys.includes(key) ? 1 : 0.2}
              yAxisId={yAxisId}
              tip={tip}
              barSize={50} // bar max size
              label={{position: 'top', formatter: (val) => `${convertNumberDotSeperated(val)}₺`}}
            >
              {data.map((dataLog, index) => (
                <Cell
                  fill={
                    calculateColor
                      ? calculateColor(dataLog)
                      : (color && theme.getThemedColor(color)) || theme.main.primaryColor
                  }
                  key={`cell-${index}`}
                />
              ))}
            </Bar>
          ))}

          <Tooltip
            cursor={{fill: '#ffffff22'}}
            content={<CustomTooltip selectedKeys={selectedKeys} isLabelDate={isDateX} />}
          />
        </RCBarChart>
      </ResponsiveContainer>
    </div>
  );
};

BarChart.defaultProps = {
  height: 200,
  data: [],
  yBarsLeft: [],
  yBarsRight: [],
  yMinLeft: 'auto',
  yMaxLeft: 'auto',
  yMinRight: 'auto',
  yMaxRight: 'auto',
  yUnitLeft: '',
  yUnitRight: '',
  tickFormatter: (tick) => tick,
  syncId: '',
  grid: true,
  title: '',
  loading: false,
};

export default BarChart;
