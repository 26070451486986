import {IUSERROLE, USERROLE} from '../constants';

export const decimalSeperator = ','; // 0,12
export const integerSeperator = '.'; // 100.000

export const convertToDotSeperated = (price: string | number) => {
  const validString = price.toString();
  if (!validString) return '0';

  const floatString = parseFloat(validString).toFixed(2).replace('.', decimalSeperator);
  return floatString.toString().replace(/\B(?=(\d{3})+(?!\d))/g, integerSeperator);
};

export const convertNumberDotSeperated = (number: string | number = 0) => {
  const validNumberString = number ? number.toString() : '';
  if (!validNumberString) return '0';
  return validNumberString.toString().replace(/\B(?=(\d{3})+(?!\d))/g, integerSeperator);
};

export const convertDotSeperatedToNumberString = (dotSeperated = '') => {
  const [integer = '', decimal = ''] = dotSeperated.split(decimalSeperator);
  const number = integer.split('.').join('');

  if (decimal) return `${number}.${decimal}`;
  return number;
};
export const convertDotSeperatedToFloat = (dotSeperated = '') =>
  parseFloat(convertDotSeperatedToNumberString(dotSeperated));

export const getInt = (value: any) => {
  return parseInt(value.toString().replaceAll('.', ''), 10);
};

/* eslint-disable prefer-regex-literals */

export const ValidatePhoneNo = (value: any) => {
  const patt = new RegExp('(5)[0-9][0-9][1-9]([0-9]){6}');
  return patt.test(value) && value.length < 11
    ? Promise.resolve()
    : Promise.reject(new Error('Lütfen geçerli bir telefon numaranızı giriniz!'));
};

export const capitalize = (s: string) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};

export const getUserRole = (value: any) => {
  const valueMap = value.split(', ');
  const roles =
    valueMap.length > 0 &&
    valueMap.reduce((newVal: any, val: IUSERROLE) => {
      newVal.push(USERROLE[val]);
      return newVal;
    }, []);
  return roles.join(', ');
};
