import React, {useEffect, useState, VFC} from 'react';
import {Button, Input, notification} from 'antd';
import {useHistory} from 'react-router-dom';
import ContentContainer from '../../../components/ContentContainer';
import Text from '../../../components/Text';
import Table from '../../../components/Table';
import PATHS from '../../../routes/paths';
import customerApi from '../../../Api/Customer';
import {CUSTOMERKIND, ICustomer} from '../../../Api/Customer/types';
import Icon from '../../../components/Icon';

const CustomerList: VFC = () => {
  const history = useHistory();
  const [isLoadingCustomers, setIsLoadingCustomers] = useState(true);
  const [customers, setCustomers] = useState<ICustomer[]>([]);
  const [customerCount, setCustomerCount] = useState(0);
  const [pagingPage, setPagingPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [deleteKey, setDeleteKey] = useState('');

  const routeToCustomerDetail = (taxOffice: string, taxNumber: number) => {
    if (!taxOffice || !taxNumber) {
      notification.warning({
        message: 'Eksik Bilgi',
        description: 'Müşterinin VKN/TCKN ve Vergi Dairesi bilgilerini kontrol ediniz.',
      });
    } else {
      history.push(PATHS.getCustomerDetailPath(taxOffice, taxNumber));
    }
  };

  const renderCol = (value: any, record: any) => (
    <div
      style={{display: 'block'}}
      role="button"
      tabIndex={-1}
      aria-hidden="true"
      onClick={() => routeToCustomerDetail(record.taxOffice, record.taxNumber)}
    >
      {value}
    </div>
  );

  const renderKindCol = (value: any, record: any) => (
    <div
      style={{display: 'block'}}
      role="button"
      tabIndex={-1}
      aria-hidden="true"
      onClick={() => routeToCustomerDetail(record.taxOffice, record.taxNumber)}
    >
      {value ? CUSTOMERKIND[value - 1] : '-'}
    </div>
  );

  const fetchCustomers = async (search = '', page = 1) => {
    try {
      setIsLoadingCustomers(true);
      const customersData = await customerApi.fetchCustomers({search, page});
      setCustomers(customersData.data);
      setCustomerCount(customersData.totalDataCount);
    } finally {
      setIsLoadingCustomers(false);
    }
  };

  const handleDelete = (value: any) => {
    setDeleteKey(value);
  };

  const deletConfirm = async () => {
    try {
      setIsLoadingCustomers(true);
      const deleteResponse = await customerApi.deleteCustomers(deleteKey);
      if (deleteResponse) fetchCustomers();
    } finally {
      setIsLoadingCustomers(false);
    }
    console.log('Delete record');
  };

  const tableCols = [
    {
      title: 'Firma Adı',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (value: any, record: any) => renderCol(value, record),
    },
    {
      title: 'Vergi Kimlik No',
      dataIndex: 'taxNumber',
      key: 'taxNumber',
      render: (value: any, record: any) => renderCol(value, record),
    },
    {
      title: 'Vergi Dairesi',
      dataIndex: 'taxOffice',
      key: 'taxOffice',
      render: (value: any, record: any) => renderCol(value, record),
    },
    {
      title: 'Şehir',
      dataIndex: 'province',
      key: 'province',
      render: (value: any, record: any) => renderCol(value, record),
    },
    {
      title: 'Tür',
      dataIndex: 'customerKindId',
      key: 'customerKindId',
      render: (value: any, record: any) => renderKindCol(value, record),
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      render: (value: string) => (
        <div style={{zIndex: 10}}>
          <div role="button" aria-hidden="true" tabIndex={0} onClick={() => handleDelete(value)}>
            <Icon size="lg" color="red" icon="trash-alt" />
          </div>
          {deleteKey === value && (
            <div className="delete-popup">
              <Text type="h5">Satırı silmek istediğinden emin misin?</Text>
              <div className="desc-btns">
                <Button onClick={() => setDeleteKey('')} type="default" className="mr">
                  Vazgeç
                </Button>
                <Button onClick={deletConfirm} style={{backgroundColor: 'red'}} type="primary">
                  Evet Sil
                </Button>
              </div>
            </div>
          )}
        </div>
      ),
    },
  ];

  const searchCustomer = (text: string) => {
    setSearchText(text);
    setPagingPage(1);
    fetchCustomers(text, 1);
  };
  const onChangePage = (page: number) => {
    setPagingPage(page);
    fetchCustomers(searchText, page);
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  return (
    <ContentContainer maxWidth={1125}>
      <Text type="h2">Firma Analizlerim</Text>
      <div className="search-input">
        <Input
          style={{marginTop: '1em', marginBottom: '2em'}}
          onChange={(e) => searchCustomer(e.target.value)}
          placeholder="Müşteri ara"
          prefix={<Icon size="lg" color="defaultSmoke" icon="search" />}
          suffix={<Button type="primary">Ara</Button>}
        />
      </div>

      <Table
        className="mt cursor-pointer"
        rowKey="id"
        dataSource={customers}
        pagination={{
          total: customerCount,
          current: pagingPage,
          onChange: onChangePage,
        }}
        columns={tableCols}
        loading={isLoadingCustomers}
      />
    </ContentContainer>
  );
};

export default CustomerList;
