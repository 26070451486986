import {SliderMarks} from 'antd/lib/slider';

export const INSURANCE_COMPANIES_BY_NAME = {
  COFACE: {value: 219, value1: 0, label: 'Coface'},
  ATRADIUS: {value: 220, value1: 0, label: 'Atradius'},
  ALLIANZ_TRADE: {value: 218, value1: 0, label: 'Allianz Trade'},
  EXIM_BANK: {value: 228, value1: 0, label: 'Eximbank'},
};

export const CURRENCY = {
  TL: {value: 11, value1: 0, label: 'TL'},
  USD: {value: 12, value1: 0, label: 'USD'},
  EUR: {value: 13, value1: 0, label: 'EUR'},
  GBP: {value: 14, value1: 0, label: 'GBP'},
};

export const CREATE_CUSTOMER_STEPS = {
  INFO: {
    key: 'INFO',
    title: 'Temel Bilgile',
    icon: 'home',
  },
  RECORD_INFO: {
    key: 'RECORD_INFO',
    title: 'Ticaret Sicil Gazetesi Bilgileri',
    icon: 'paper',
  },
  WARRANT_INFO: {
    key: 'WARRANF_INFO',
    title: 'Teminat Bilgileri',
    icon: 'check-circle',
  },
  INSURANCE_INFO: {
    key: 'INSURANCE_INFO',
    title: 'Alacak Sigortası Bilgileri',
    icon: 'umbrella',
  },
  FINDEKS_INFO: {
    key: 'FINDEKS_INFO',
    title: 'Findeks Bilgileri',
    icon: 'increase-circle',
  },
  FINANCIAL_INFO: {
    key: 'FINANCIAL_INFO',
    title: 'Mali Tablolar',
    icon: 'rotation',
  },
  LEGAL_DOCUMENTS: {
    key: 'LEGAL_DOCUMENTS',
    title: 'Legal Evraklar',
    icon: 'cards',
  },
  WORKING_CONDITIONS_INFO: {
    key: 'WORKING_CONDITIONS_INFO',
    title: 'Çalışma Koşulları',
    icon: 'bag',
  },
};

export const CREATE_SALES_FIELD_INFO = {
  SALES_FIELD_INFO: {
    key: 'SALES_FIELD_INFO',
    title: 'Satış Saha Bilgileri',
    description:
      'Müşteri tanımlamak için Satış Saha Bilgileri ve Satış Birimi Çalışma Koşulu alanlarını doldurunuz.',
    icon: 'icon-saha-satis',
  },
  COMPANY_INFO: {
    key: 'COMPANY_INFO',
    title: 'Firma Bilgisi',
    description: '',
    icon: 'icon-ticari',
  },
  SALES_UNIT_WORK_CONDITION: {
    key: 'SALES_UNIT_WORK_CONDITION',
    title: 'Satış Birimi Çalışma Koşulu',
    description:
      'Müşteri tanımlamak için Satış Saha Bilgileri ve Satış Birimi Çalışma Koşulu alanlarını doldurunuz.',
    icon: 'icon-ticari',
  },
  COMPANY_IDENTIFIER_INFO: {
    key: 'COMPANY_IDENTIFIER_INFO',
    title: 'Firma Tanıtıcı Bilgileri',
    description:
      'Müşteri tanımlamak için Satış Saha Bilgileri ve Satış Birimi Çalışma Koşulu alanlarını doldurunuz.',
    icon: 'icon-irket',
  },
  SALES_UNIT_FEEDBACK: {
    key: 'SALES_UNIT_FEEDBACK',
    title: 'Satış Birimi Görüşleri',
    description:
      'Müşteri tanımlamak için Satış Saha Bilgileri ve Satış Birimi Çalışma Koşulu alanlarını doldurunuz.',
    icon: 'icon-gor-s',
  },
};

export type IUSERROLE = keyof typeof USERROLE;

export const USERROLE = {
  ADMIN: 'Admin',
  MANAGER: 'Yönetici',
  CREDITOR: 'Kredi/Risk Analisti',
  SELLER: 'Satış Temsilcisi',
};

export const marks: SliderMarks = {
  0: '0',
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  10: '10',
};

export const marksAtradius: SliderMarks = {
  0: '0',
  10: '10',
  20: '20',
  30: '30',
  40: '40',
  50: '50',
  60: '60',
  70: '70',
  80: '80',
  90: '90',
  100: '100',
};
