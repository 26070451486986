import React, {FC, useEffect, useState} from 'react';
import {Col, Form, Input, notification, Row, Select, Slider, Spin} from 'antd';
import moment from 'moment';
import Button from '../../../../components/Button';
import DatePicker from '../../../../components/DatePicker';
import Text from '../../../../components/Text';
import ProgressStep from '../../../../components/ProgressStep';
import Upload from '../../../../components/Upload';
import {convertFileToAntdFile, FilePromise, getBase64, normFile} from '../../../../utils/file';
import {ICustomerCreateComponent} from '../types';
import {useAppDispatch, useAppSelector} from '../../../../hooks/useRedux';
import customerApi from '../../../../Api/Customer';
import {fetchInsuranceCompanies} from '../../../../store/globals/globalsActions';
import {INSURANCE_COMPANIES_BY_NAME, marks, marksAtradius} from '../../../../constants';
import InputPrice from '../../../../components/InputPrice';
import {convertDotSeperatedToFloat, convertNumberDotSeperated} from '../../../../utils/string';
import searchApi from '../../../../Api/Search';
import {IApiImage} from '../../../../Api/types';

const {Option} = Select;

const InsuranceInfo: FC<ICustomerCreateComponent> = ({onSubmit, onCancel}) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [insuranceData, setInsuranceData] = useState<any>(null);
  const [insuranceCompanyId, setInsuranceCompanyId] = useState<number>(0);
  const [isLoadingInitialData, setIsLoadingInitialData] = useState(true);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const customer = useAppSelector((state) => state.customer.customerCreateQueryData);
  const {insuranceCompanies, isLoadingInsuranceCompanies} = useAppSelector(
    (state) => state.globals,
  );

  const fetchInitialData = async () => {
    const initialData: any = await customerApi.fetchCustomerInsuranceInfo(customer.accountId);
    const momentDate =
      (initialData?.decisionDate &&
        initialData?.decisionDate !== '0001-01-01T00:00:00' &&
        moment(initialData.decisionDate)) ||
      null;

    const momentValidityDate =
      (initialData?.validityDate &&
        initialData?.validityDate !== '0001-01-01T00:00:00' &&
        moment(initialData.validityDate)) ||
      null;

    setInsuranceData(initialData);

    const draFileDocument =
      initialData?.draHistoryDocument && (await FilePromise(initialData.draHistoryDocument));

    const draFile = draFileDocument && convertFileToAntdFile(draFileDocument as File);

    form.setFieldsValue({
      ...initialData,
      decisionDate: momentDate || null,
      validityDate: momentValidityDate || null,
      acceptedAmount: convertNumberDotSeperated(initialData.acceptedAmount),
      requestedAmount: convertNumberDotSeperated(initialData.requestedAmount),
      draScore: initialData.draRank,
      upload: draFile ? [draFile] : [],
      insuranceCompanyId:
        initialData?.insuranceCompanyId === 0
          ? (insuranceCompanies && insuranceCompanies.length > 0 && insuranceCompanies[0]?.value) ||
            0
          : initialData.insuranceCompanyId,
    });
    setInsuranceCompanyId(initialData.insuranceCompanyId);
    setIsLoadingInitialData(false);
  };

  const handleOnSubmit = async (formValues: any) => {
    try {
      setIsLoadingSubmit(true);
      const {
        acceptedAmount,
        evaluationDescription,
        requestedAmount,
        decisionDate,
        validityDate,
        upload,
      } = formValues;
      const uploadReport: IApiImage | undefined =
        upload && upload[0]?.originFileObj && (await getBase64(upload[0].originFileObj));
      await customerApi.uploadCustomerInsuranceInfo({
        accountId: customer.accountId,
        acceptedAmount: convertDotSeperatedToFloat(acceptedAmount),
        requestedAmount: convertDotSeperatedToFloat(requestedAmount),
        decisionDate: decisionDate?.toISOString(),
        validityDate: validityDate?.toISOString(),
        evaluationDescription,
        insuranceCompanyId,
        draHistoryDocument: uploadReport || undefined,
      });
      onSubmit();
    } catch {
      setIsLoadingSubmit(false);
    }
  };

  useEffect(() => {
    fetchInitialData();
    dispatch(fetchInsuranceCompanies());
  }, []);

  const handleDeleteDocument = (file: any, key: any) => {
    searchApi.deleteDocument(file.tciDoumentId).then((res) => {
      console.log(res, 'res');
      notification.success({message: 'İşlem Başarılı', description: 'Dosya silindi yanıtı'});
      form.setFieldsValue({[key]: []});
    });
  };

  const SelectAfter = (
    <Select className="curency-dp" style={{width: 70}} autoClearSearchValue>
      <Option value="TRY">TRY</Option>
      <Option value="USD">USD</Option>
      <Option value="EUR">EUR</Option>
      <Option value="GBP">GBP</Option>
    </Select>
  );

  return (
    <Spin spinning={isLoadingInitialData}>
      <ProgressStep className="mb-big" title="Alacak Sigortası Bilgileri" value={4} steps={8} />
      <Text type="h2" bold>
        Alacak Sigortası Bilgileri
      </Text>

      <Form
        form={form}
        name="insuranceInfo"
        layout="vertical"
        requiredMark={false}
        initialValues={{company: customer.title}}
        onFinish={handleOnSubmit}
      >
        <Row>
          <Col span={10}>
            <Form.Item name="company" label="Firma Adı">
              <Input disabled />
            </Form.Item>

            <Form.Item name="insuranceCompanyId" label="Sigorta Adı">
              <Select
                onChange={(val) => setInsuranceCompanyId(val)}
                options={insuranceCompanies}
                loading={isLoadingInsuranceCompanies}
              />
            </Form.Item>

            {(form.getFieldValue('insuranceCompanyId') ===
              INSURANCE_COMPANIES_BY_NAME.COFACE.value ||
              form.getFieldValue('insuranceCompanyId') ===
                INSURANCE_COMPANIES_BY_NAME.ATRADIUS.value ||
              form.getFieldValue('insuranceCompanyId') ===
                INSURANCE_COMPANIES_BY_NAME.EXIM_BANK.value ||
              form.getFieldValue('insuranceCompanyId') ===
                INSURANCE_COMPANIES_BY_NAME.ALLIANZ_TRADE.value) && (
              <>
                {form.getFieldValue('insuranceCompanyId') ===
                  INSURANCE_COMPANIES_BY_NAME.COFACE.value && (
                  <>
                    <Text type="h4" weight="initial" bold>
                      DRA Geçmiş
                    </Text>

                    <Form.Item name="upload" valuePropName="fileList" getValueFromEvent={normFile}>
                      <Upload
                        label="JPEG Yükle"
                        accept="image/jpeg"
                        documentId={insuranceData?.draHistoryDocument?.tciDoumentId}
                        onRemove={() =>
                          handleDeleteDocument(insuranceData?.draHistoryDocument, 'upload')
                        }
                      />
                    </Form.Item>
                  </>
                )}

                <Form.Item name="draScore" label="DRA Notu">
                  <Slider
                    min={
                      form.getFieldValue('insuranceCompanyId') ===
                      INSURANCE_COMPANIES_BY_NAME.ALLIANZ_TRADE.value
                        ? 1
                        : 0
                    }
                    max={
                      form.getFieldValue('insuranceCompanyId') ===
                      INSURANCE_COMPANIES_BY_NAME.ATRADIUS.value
                        ? 100
                        : 10
                    }
                    marks={
                      form.getFieldValue('insuranceCompanyId') ===
                      INSURANCE_COMPANIES_BY_NAME.ATRADIUS.value
                        ? marksAtradius
                        : marks
                    }
                  />
                </Form.Item>
              </>
            )}

            <Form.Item name="requestedAmount" label="Talep Edilen Limit">
              <InputPrice
                displayPrefix={false}
                addonAfter={
                  <Form.Item initialValue="TRY" name="requestCurrency" noStyle>
                    {SelectAfter}
                  </Form.Item>
                }
                className="w-100"
              />
            </Form.Item>

            <Form.Item name="acceptedAmount" label="Onaylanan Limit">
              <InputPrice
                displayPrefix={false}
                addonAfter={
                  <Form.Item initialValue="TRY" name="acceptCurrency" noStyle>
                    {SelectAfter}
                  </Form.Item>
                }
                className="w-100"
              />
            </Form.Item>

            <Form.Item name="evaluationDescription" label="Notlar">
              <Input.TextArea rows={4} />
            </Form.Item>

            <Form.Item name="decisionDate" label="Karar Tarihi">
              <DatePicker className="w-100" format="DD-MM-YYYY" />
            </Form.Item>

            <Form.Item name="validityDate" label="Limit Geçerlilik Tarihi">
              <DatePicker className="w-100" format="DD-MM-YYYY" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={10}>
            <Form.Item className="m-0">
              <Button type="primary" htmlType="submit" size="large" loading={isLoadingSubmit} block>
                Kaydet ve İlerle
              </Button>
            </Form.Item>
          </Col>
          <Col span={8} className="pl">
            <Form.Item className="m-0">
              <Button
                type="default"
                size="large"
                loading={isLoadingSubmit}
                onClick={onCancel}
                block
              >
                Kaydet ve Çık
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default InsuranceInfo;
