import React, {useEffect, useState, VFC} from 'react';
import {Col, Row, Spin} from 'antd';
import {useHistory, useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import ContentContainer from '../../../components/ContentContainer';
import Icon from '../../../components/Icon';
import Text from '../../../components/Text';
import CustomerInfoStatus from './CustomerInfoStatus';
import customerApi from '../../../Api/Customer';
import {
  ICustomerQuery,
  ICustomerRecordData,
  ICustomerWorkingConditionsData,
} from '../../../Api/Customer/types';
import {convertNumberDotSeperated, convertToDotSeperated} from '../../../utils/string';
import paths from '../../../routes/paths';
import {useAppSelector} from '../../../hooks/useRedux';
import {fetchCurrencyTypes, fetchPaymentTypes} from '../../../store/globals/globalsActions';
import {CREATE_CUSTOMER_STEPS} from '../../../constants';
import {setCustomerQueryData} from '../../../store/customer/customerActions';

const customerInfoStatusItems: {key: string; text: string; icon: string}[] = [
  {
    key: CREATE_CUSTOMER_STEPS.RECORD_INFO.key,
    text: `${CREATE_CUSTOMER_STEPS.RECORD_INFO.title}`,
    icon: CREATE_CUSTOMER_STEPS.RECORD_INFO.icon,
  },
  {
    key: CREATE_CUSTOMER_STEPS.WARRANT_INFO.key,
    text: CREATE_CUSTOMER_STEPS.WARRANT_INFO.title,
    icon: CREATE_CUSTOMER_STEPS.WARRANT_INFO.icon,
  },
  {
    key: CREATE_CUSTOMER_STEPS.INSURANCE_INFO.key,
    text: CREATE_CUSTOMER_STEPS.INSURANCE_INFO.title,
    icon: CREATE_CUSTOMER_STEPS.INSURANCE_INFO.icon,
  },
  {
    key: CREATE_CUSTOMER_STEPS.FINDEKS_INFO.key,
    text: CREATE_CUSTOMER_STEPS.FINDEKS_INFO.title,
    icon: CREATE_CUSTOMER_STEPS.FINDEKS_INFO.icon,
  },
  {
    key: CREATE_CUSTOMER_STEPS.FINANCIAL_INFO.key,
    text: CREATE_CUSTOMER_STEPS.FINANCIAL_INFO.title,
    icon: CREATE_CUSTOMER_STEPS.FINANCIAL_INFO.icon,
  },
  {
    key: CREATE_CUSTOMER_STEPS.LEGAL_DOCUMENTS.key,
    text: CREATE_CUSTOMER_STEPS.LEGAL_DOCUMENTS.title,
    icon: CREATE_CUSTOMER_STEPS.LEGAL_DOCUMENTS.icon,
  },
  {
    key: CREATE_CUSTOMER_STEPS.WORKING_CONDITIONS_INFO.key,
    text: CREATE_CUSTOMER_STEPS.WORKING_CONDITIONS_INFO.title,
    icon: CREATE_CUSTOMER_STEPS.WORKING_CONDITIONS_INFO.icon,
  },
];

const CustomerDetail: VFC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams() as {taxOffice: string; taxNumber: string};

  const [isLoading, setIsLoading] = useState(true);
  const [customer, setCustomer] = useState<ICustomerQuery>();
  const [ciroData, setCiroData] = useState<any>();
  const [customerGeneral, setCustomerGeneral] = useState<ICustomerRecordData>();
  const [workingCondition, setWorkingCondition] = useState<ICustomerWorkingConditionsData>();
  const [stepStatuses, setStepStatuses] = useState<Record<number, boolean>>({});
  const {paymentTypes, isLoadingPaymentTypes, currencyTypes, isLoadingCurrencyTypes} =
    useAppSelector((state) => state.globals);

  const fetchCustomerDetail = async () => {
    try {
      setIsLoading(true);
      const detail = await customerApi.queryCustomer(params.taxNumber, params.taxOffice);
      if (detail.accountId) {
        const ciroRes: any = await customerApi.fetchCustomerCiroInfoV2(detail.accountId);
        ciroRes?.length > 0 && setCiroData(ciroRes[0]);
        const customerGeneralInfo = await customerApi.fetchCustomerRecordInfo(detail?.accountId);
        const statuses = await customerApi.fetchCreateStepStatuses(detail?.accountId);
        const workingConditionData = await customerApi.fetchCustomerWorkingConditionsInfo(
          detail?.accountId,
        );
        setCustomer(detail);
        setCustomerGeneral(customerGeneralInfo);
        setStepStatuses(statuses);
        setWorkingCondition(workingConditionData);
        setIsLoading(false);
      }
    } catch {
      history.push(paths.customer);
    }
  };

  useEffect(() => {
    fetchCustomerDetail();
  }, [params]);

  useEffect(() => {
    dispatch(fetchPaymentTypes());
    dispatch(fetchCurrencyTypes());
  }, []);

  const onClickUpdateCustomer = (key: string) => {
    if (customer) {
      dispatch(setCustomerQueryData(customer));
      history.push({
        pathname: paths.customerCreate,
        state: {activeTabKey: key},
      });
    }
  };
  const handleReport = () => {
    history.push(paths.getCustomerInfoPath(params.taxOffice, params.taxNumber));
  };
  return (
    <Spin spinning={isLoading || isLoadingPaymentTypes || isLoadingCurrencyTypes}>
      <ContentContainer>
        <Row className="mb-big" align="middle" gutter={20}>
          <Col>
            <Text type="h2">Firma</Text>
          </Col>

          <Col onClick={handleReport}>
            <Text type="h4" underlined>
              Detaylı Rapor Al <Icon icon="file-download" />
            </Text>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Text bold block>
              Ticaret Unvanı:
            </Text>
            <Text>{customer?.title}</Text>
            <br />
            <br />
            <Text bold block>
              Faaliyet Alanı:
            </Text>
            <Text>{customer?.operationDetail}</Text>
            <br />
            <br />
            <Text bold block>
              Teminat:
            </Text>
            <Text>{workingCondition?.guaranteeSummary?.total}</Text>
            <br />
            <br />
            <Text bold block>
              Sermaye:
            </Text>
            <Text>{customerGeneral?.totalEquity || '-'}</Text>
            <br />
            <br />
            <Text bold block>
              Ciro:
            </Text>
            <Text>
              {(ciroData &&
                convertNumberDotSeperated(
                  ciroData[`endorsement${new Date().getFullYear() - 1}`],
                )) ||
                '-'}{' '}
              TL
            </Text>
            <br />
            <br />

            <Text bold block>
              Beyan Olunan Matrah:
            </Text>
            {/* TODO: COMPONENT */}
            <div className="p light-smoke-background d-inline-block radius" style={{minWidth: 400}}>
              <Row justify="space-between" gutter={20}>
                {customer?.profits.map(({year, taxAssessment}, index) => (
                  <Col key={index}>
                    <Text block bold>
                      {year}
                    </Text>
                    <Text>{convertToDotSeperated(taxAssessment)} TL</Text>
                  </Col>
                ))}
              </Row>
            </div>
            <br />
            <br />
            <Text bold block>
              Çalışma Koşulları:
            </Text>
            {/* TODO: COMPONENT */}
            <div className="p light-smoke-background d-inline-block radius" style={{minWidth: 400}}>
              <Row justify="space-between" gutter={20}>
                <Col>
                  <Text block bold>
                    Vade
                  </Text>
                  <Text>{workingCondition?.salesCondition?.monthOfTermCount}</Text>
                </Col>
                <Col>
                  <Text block bold>
                    Ödeme Şekli:
                  </Text>
                  <Text>
                    {
                      paymentTypes.find(
                        ({value}) =>
                          value === workingCondition?.salesCondition?.paymentMethodParameterId,
                      )?.label
                    }
                  </Text>
                </Col>
                <Col>
                  <Text block bold>
                    Limit
                  </Text>
                  <Text>
                    {convertToDotSeperated(workingCondition?.salesCondition?.limitAmount || 0)}{' '}
                    {
                      currencyTypes.find(
                        ({value}) =>
                          value === workingCondition?.salesCondition?.limitAmountCurrencyParamterId,
                      )?.label
                    }
                  </Text>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={12}>
            {customerInfoStatusItems.map(({key, text, icon}, idx) => (
              <CustomerInfoStatus
                className="mb hover-scale"
                key={key}
                icon={icon}
                text={text}
                status={stepStatuses?.[idx + 1]}
                onClick={() => onClickUpdateCustomer(key)}
              />
            ))}
          </Col>
        </Row>
      </ContentContainer>
    </Spin>
  );
};

export default CustomerDetail;
